import Vue from 'vue';  // Vue 2 import
import App from './App.vue';
import VueMeta from 'vue-meta';
import { BootstrapVue, IconsPlugin, CarouselPlugin } from 'bootstrap-vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/style/styles.css';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CarouselPlugin);
Vue.use(VueMeta);

new Vue({
  render: h => h(App),
  router, // include the router
}).$mount('#app'); // mount the Vue instance
router.beforeEach((to, from, next) => {
  // Set document title
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Set meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || '');
  } else {
    const descriptionTag = document.createElement('meta');
    descriptionTag.setAttribute('name', 'description');
    descriptionTag.setAttribute('content', to.meta.description || '');
    document.head.appendChild(descriptionTag);
  }

  // (Optional) Set keywords
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute('content', to.meta.keywords || '');
  } else {
    const keywordsTag = document.createElement('meta');
    keywordsTag.setAttribute('name', 'keywords');
    keywordsTag.setAttribute('content', to.meta.keywords || '');
    document.head.appendChild(keywordsTag);
  }

  next();
});
